
html,
body
{

    box-sizing: border-box;
    background-color: white;
    margin: 0;
    width:100%;
    height:100%;
    min-height: 100%;
    /* mobile viewport bug fix */
    /* min-height: -webkit-fill-available; */
    overflow: hidden;
    touch-action: none;
}

.container
{
    /*  background-color: rgb(213, 255, 213); */
    padding: 1rem;
    padding-top: 0;
    /*height:100%;*/
}

.container-scroll
{
  overflow: auto;
  padding: 1rem;
  height: 100%;
}

.infoWrapper{
    position: relative;
    width: calc(100vw - 2rem);
    min-height: 5rem;;
    bottom: 1rem;
    left: 0;
    align-content: center;
  /*  background-color: rgb(189, 223, 255); */

}
.buttonWrapper{
    position: fixed;
    width: calc(100vw - 2rem);
    min-height: 5rem;;
    bottom: 1rem;
    left: 1rem;
    vertical-align: bottom;
    align-content: center;
  /* background-color: violet; */

}
.buttonSpacer{
  display: inline-block;
  width: .5rem;
}

.column-left {
  float: left;
  width: 33.333%;
}

.column-right {
  float: right;
  width: 33.333%;
}

.column-center {
  display: inline-block;
  width: 33.333%;
}

.imageFullWidth{
  /*position: absolute;
  top:2rem;*/
  left:0;
  width: calc(100vw - 2rem);
  }


