/* 
    any div that is a parent of map must have this class 
*/
.full
{
    width:100%;
    height:100%;
}

/** 
    logo 
*/
.logo-absolute{
    position:absolute;
    top:0px;
    z-index: 3000;
}
.logo{
    border:0;
    width:50vw;
    height:4rem;
    padding-top: .8rem;
    pointer-events:none; /* allow click through */
  /*  background-color: rgb(213, 238, 255, .5); */
}



/* 
    the map container 
*/
.map{
    position: relative;
    top:0;
    left:0;
    width:100%;
    height:100%;
    transition: left .3s ease;
    -webkit-transition: left .3s ease;
}

.map-left
{
    left:-25%;
}


/*  
    the button that triggers centering on user's position
*/
.center-me
{
    position:absolute;
    width:3rem;
    height:3rem;
    background-color:none;
    top: 1rem;
    right:1rem;
    z-index: 1; 

    display:block;
	background-image: url('../img/Center_Me-01.svg');
	background-size: 100% 100%;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.center-me-active
{
   /* background-color: white; */
   background-image: url('../img/Center_Me-03.svg');

}



/* 
    notifications container
*/
.notifications
{
    position:absolute;
    top:0px;
    z-index: 1000;
    width: 100vw;
}


/* 
    notification message
*/
.notification
{
    margin: 1rem;
    padding: 1rem;
    color:white;
}
.notification-success
{
    background-color: #1a7c1abb;
}
.notification-warning
{
    background-color: #4a288abb;
}
.notification-error
{
    background-color: #9c0000c9;
}


/* 
    Selected Spot footer
*/
.footer
{
    position:absolute;
    height:3rem;
    width:calc(100vw - 1rem);
    padding: .5rem;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    z-index: 10;
    bottom: 0;
    opacity: 1;
    transition: bottom .3s ease, opacity 0.3s ease;
    -webkit-transition: bottom .3s ease, opacity 0.3s ease;
    -moz-transition: bottom .3s ease, opacity 0.3s ease;
    -o-transition: bottom .3s ease, opacity 0.3s ease;
}

.footer-closed{
    bottom: -10vh;
    opacity: 0;
}

.footerContent
{
    /*margin: 0 auto;*/
}



/* 
    SpotList component
*/

.spotlist
{
    position: absolute;
    width: 50vw;
    height:100%;
    right:0%;
    /* padding-top: 1rem; nope : ) */

    background-color: rgb(255, 255, 255);
    opacity: 1;

    overflow-y: scroll; /* must be scroll for next statement */
    -webkit-overflow-scrolling: touch; /* fluid scroll on ios */
    touch-action: none;
    z-index: 1001;

    transition: right .3s ease, opacity .5s ease;
    -webkit-transition: right .3s ease, opacity .5s ease;
    -moz-transition: right .3s ease, opacity .5s ease;
    -o-transition: right .3s ease, opacity .5s ease;
}

.spotlist-closed{
    right:-50%;
   /* opacity: 0;*/
}

/* 
    button
    button-selected is triggered when spot selected by user
*/
.button{
    width: calc(100vw - 2rem); 
    /*bottom: 1rem;*/
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0 auto;

	background-color:rgba(255, 255, 255, 0.9);
	border-radius:0px;
	border:2px solid #000;
	display:inline-block;
}


.spotlist .button{

    width: calc(50vw - 2rem);
    display:inline-block;

    min-height: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-left: 1rem;

    border: 0;
    border-top:1px solid rgb(105, 105, 105);
    color:rgb(0, 0, 0);
    text-align: center;
}

.button p{
    text-align: center;
    padding: 0rem;
}

.button-selected
{
    background-color: rgb(231, 229, 229);
    color:white;
}




/* 
    SpotView component (mediaplayer)
*/
.spotView
{
    position: absolute;
    top:0%;
    left:0px;
    width:100%;
    height:100%;
    background: white;
    z-index: 2000;
}

.spotView .button{
    display: inline-block;
    width: calc(((100vw - 0.01rem) / 3) - 1rem);
 /* height:1.5rem;
    border: none;
    color: white;
    background-color:black;
    margin-top:10em; */
}



.spotView .audioplayer{
    display: inline-block;
}

.audioplayer{
    position: absolute;
    width:calc(100% - 2rem);
    vertical-align: bottom;
    bottom: 9rem;
    margin-top:20px;
    height:5rem;
}


/* 
    Spot map marker, represented by svg image file -> ./static/SpotMarker.svg
*/
.spot-marker{
    width:64px;
    height:64px;
    transition: width 0.5s ease, height 0.5s ease;
    -webkit-transition: width 0.5s ease, height 0.5s ease;
    -moz-transition: width 0.5s ease, height 0.5s ease;
    -o-transition: width 0.5s ease, height 0.5s ease;
}
.spot-marker-selected{
    width: 128px; 
    height: 128px; 
    z-index: 5;
}

/* close button (InfoView and SpotView) */
.close-button{
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    z-index: 1602;
}