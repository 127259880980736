@import url('https://rsms.me/inter/inter.css');

html,
body
{
    font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
    font-size: calc(1vmin + 1.7vmax);
    padding: 0;
}


h1{
    font-size: 2rem ;
    font-weight: 400;
    line-height: 1.25 rem;
    /*margin-top: 0;*/
    /*padding-bottom: 1rem;*/

}

h1.indent{
    margin-left: 33%;
}


h2{
    font-size: 1.5rem ;
    font-weight: 400;
    line-height: 1.25 rem;
    padding-bottom: .75rem;
     /*   background-color: rgb(213, 255, 213); */

}

h2.indent{
    margin-left: 33%;
}


h3{
    font-size: 1rem ;
    line-height: 1.25em;
    font-weight: 400;
    margin:0;
    padding-bottom: .5;

}

p{
    font-size: 1rem ;
    line-height: 1.25rem;

    /*margin:0;*/
    margin-bottom: .5rem;
}

p neg{
    color: #ffffff
}

a{
    text-decoration: none;
    color: black;
}

.footer p
{
    /*display: inline-block;*/
    line-height: 1.25rem;
    padding: 0;
    margin:0;
   /* text-align: center;*/
   /*color: #9c4141*/

}

.small-size {
    font-size: .8rem ;
    line-height: 1.25rem;
    /*color: #9c4141*/

}

.small-size.neg {
    color: #ffffff
}

.attribution {
    font-size: .6rem ;
    line-height: 1.25rem;
    color: #aaa;

}

.textCenter{
    text-align: center;
    /*margin:auto;*/
    padding: 0;
}

.textCenter.neg{
    color:#ffffff
 }



p.interview_question{
    text-decoration: underline;
    /* width: calc(100vw - 2.8rem); */
    margin-top: 2rem;
    margin-left: 1rem;
}

.text-indent{
    margin-top:0;
    padding: 0;
    margin-left: 20%;
    /* width: calc(80vw - 2.8rem); */
}

.button{
    font-size: 1.25rem;
    min-height: 5rem;
}

.button-desktop{
    font-size: 1rem;
    min-height: 3rem;
}


.logo {
    font-size: .8rem; ;
    line-height: .5 rem;
}

.logo.neg {
  /*  font-size: .7rem; ;
    line-height: .5 em; */
    color:black;
}







.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
